/*var fontsLoaded = false,
    imagesLoaded = false;

WebFontConfig = {
    typekit: {
        id: 'rmj1tpw'
    },

    active: function() {
        fontsLoaded = true;
        console.log('fonts loaded');
    }
};

(function() {
    var wf = document.createElement('script');
    wf.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();

*/
