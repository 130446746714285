if ( !document.documentElement.classList.contains('flexbox') ) {
    let oldBrowser = document.querySelector('.old-browser');
    let oldBrowserAccept = document.querySelector('.old-browser-accept');

    if ( !Cookies.get('siteacceptcookies') ) {
        oldBrowser.classList.add('visible');
        oldBrowser.setAttribute('aria-hidden', false);
    }

    oldBrowserAccept.addEventListener('click', function() {
        Cookies.set('siteacceptcookies', 'yes');
        oldBrowser.classList.remove('visible');
        oldBrowser.setAttribute('aria-hidden', true);
    });
}
