/**
 * accessibility outlines
 * lifted in whole from:
 * https://gist.github.com/davidgilbertson/1955dc1dde36e1fabbc6178052294560#file-unnecessarily-complicated-version-js
 * after being found at:
 * https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
 */

const handleFirstTab = function(e) {
    if (e.keyCode === 9) {
        document.body.classList.add('keyboard-use');
        document.body.classList.remove('no-focus');
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
}

const handleMouseDownOnce = function(e) {
    document.body.classList.remove('keyboard-use');
    document.body.classList.add('no-focus');
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
}

window.addEventListener('keydown', handleFirstTab);
